import config from "./luanchuan";

const global = {
  CopyRight: "©·2020-2080 河南安洛云信息科技有限公司版权所有权",
  // CopyRight: "©·2020-2080 中国电信股份有限公司洛阳分公司版权所有权",
  // CopyRight: "©·2020-2022 河南得贤人力资源服务有限公司版权所有权",
  Record: "备案号：豫ICP备17050318号"
}

export default Object.assign(global, config);
// document.querySelector("title").innerText = config.objectName;