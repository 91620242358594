export default {
    area: "栾川城关镇",
    objectName: "",
    //url
    // hostUrl : 'http://cuiyun.dexian.ren/',
    apiHttpsUrl : 'https://lccgzapi.zhsq.cloud/',
    apiRequestUrl : 'https://lccgzapi.zhsq.cloud/api',
    apiUploadUrl: 'https://lccgzapi.zhsq.cloud/api/UploadFile/UploadImgs',
    apiUploadFiles:'https://lccgzapi.zhsq.cloud/api/UploadFile/UploadFiles',
    apiUploadvideo:'https://lccgzapi.zhsq.cloud/api/UploadFile/UploadQiniuVideo',
}